var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-drawer',{attrs:{"zIndex":1000,"title":_vm.dialog.type === 'add'? 'Add New Amenity' : 'Edit Amenity',"width":720,"visible":_vm.dialog.show,"wrapStyle":{overflow: 'auto'},"maskClosable":false},on:{"close":_vm.onClose}},[_c('bh-loading',{attrs:{"show":_vm.loading}}),_c('a-form-model',{ref:"newAmenity",staticClass:"h-full df fC jSB",attrs:{"model":_vm.newAmenity}},[_c('div',{staticClass:"f1"},[_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-model-item',{attrs:{"prop":"name","label":"Amenity Name","required":"","rules":_vm.req('Please enter the Amenity Name')}},[_c('a-input',{attrs:{"placeholder":"Example: Gym"},model:{value:(_vm.newAmenity.name),callback:function ($$v) {_vm.$set(_vm.newAmenity, "name", $$v)},expression:"newAmenity.name"}})],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-model-item',{attrs:{"label":"Description"}},[_c('a-textarea',{attrs:{"placeholder":"Description of the Amenity","auto-size":{ minRows: 5, maxRows: 6 }},model:{value:(_vm.newAmenity.description),callback:function ($$v) {_vm.$set(_vm.newAmenity, "description", $$v)},expression:"newAmenity.description"}})],1)],1)],1),_c('a-form-model-item',{attrs:{"label":"Featured Image","required":""}},[_c('div',{staticStyle:{"width":"50%"}},[_c('ImageBox',{attrs:{"type":2,"img":_vm.newAmenity.image},on:{"callback":_vm.selectImage}})],1)]),_c('a-form-model-item',{attrs:{"label":"Media"}},[_c('div',{staticStyle:{"width":"50%"}},[_c('ImageBox',{attrs:{"type":2,"img":_vm.newAmenity.video},on:{"callback":_vm.selectVideo}})],1)])],1),_c('div',{style:({
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            })},[_c('div',{staticClass:"dF jSB"},[_c('div',[(_vm.dialog.type === 'edit')?_c('a-button',{attrs:{"size":"large","type":"danger","ghost":"","icon":"delete"},on:{"click":_vm.deleteAmenity}},[_vm._v("DELETE")]):_vm._e()],1),_c('div',[_c('a-button',{staticClass:"mr-3 cancel-button",attrs:{"size":"large"},on:{"click":_vm.onClose}},[_vm._v(" CANCEL ")]),_c('a-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.submitButton}},[_vm._v(_vm._s(_vm.dialog.type === 'add'? 'CREATE' : 'UPDATE'))])],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <a-drawer
      style="overflow-y:scroll"
      :zIndex="1000"
      :title="dialog.type === 'add'? 'Assign to a Unit' : 'Edit Unit'"
      :width="720"
      @close="onClose"
      :visible="dialog.show"
      :wrapStyle="{ overflow: 'auto'}"
      :maskClosable="false"
    >
      <bh-loading :show="loading" />
      <a-form-model class="h-full dF fC jSB">

        <div class="f1">
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-model-item label="Unit No." required>
                <a-select v-model="lot.unit" placeholder="Tags Mode">
                  <a-select-option v-for="(unit) in units" :key="unit.id">{{unit.name}}</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
          </a-row>


          <a-form-model-item label="Featured Image" required>
            <div style="width:50%">
              <ImageBox :type=2 :img="lot.image" @callback="selectImage" />
            </div>
            <!-- <a-row :gutter="16" class="mr-3">
              <a-col :span="20">
                  <a-input :disabled="true" v-model="lot.image"></a-input>
                  <p>Minimum upload file size: 1MB.</p>
              </a-col>
              <a-col :span="4">
                <a-button @click="$store.commit('MEDIA_SELECT',{callback:selectImage,type:'images'})" style="background-color:var(--light-gray)"><a-icon type="plus" />ADD IMAGE</a-button>
              </a-col>
            </a-row> -->
          </a-form-model-item>


          <a-form-model-item label="Media">
            <div style="width:50%">
              <ImageBox :promptText="'Add Media'" :mediaType="'all'" :uploadText="'UPLOAD MEDIA'" :removeText="'REMOVE MEDIA'" :type=2 :img="lot.video" @callback="selectVideo" />
            </div>
            <!-- <a-row :gutter="16" class="mr-3">
              <a-col :span="20">
                  <a-input :disabled="true" v-model="lot.video"></a-input>
                  <p>Minimum upload file size: 5MB.</p>
              </a-col>
              <a-col :span="4">
                <a-button @click="$store.commit('MEDIA_SELECT',{callback:selectVideo,type:'video'})" style="background-color:var(--light-gray)"><a-icon type="plus" />ADD VIDEO</a-button>
              </a-col>
            </a-row> -->
          </a-form-model-item>



        </div>

        <div
          :style="{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }"
        >
        <div class="dF jSB">
          <div>
              <a-button size="large" @click="deleteLot" v-if="dialog.type === 'edit'" type="danger" ghost icon="delete">DELETE</a-button>
          </div>
          <div>
            <a-button size="large" class="mr-3 cancel-button" @click="onClose">
              CANCEL
            </a-button>
            <a-button type="primary" size="large" @click="submitButton">{{dialog.type === 'add'? 'CREATE' : 'UPDATE'}}</a-button>
          </div>
        </div>

        </div>

      </a-form-model>

    </a-drawer>


</template>

<script>
  import {formatNumber} from 'bh-mod'
  import bhLoading from 'bh-mod/components/common/Loading'
  import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
  import Expander from 'bh-mod/components/common/Expander'
  import { TreeSelect } from 'ant-design-vue';
  import ImageBox from '@/components/siteplan/ImageBox'
  export default {
    components:{bhLoading,ImageBoxSelector,Expander,ImageBox},

    data() {

      return {
        loading:false,
        lot:{
          unit:'Choose Your Unit',
          image:'',
          video:'',
          siteplan:'',
          spots:'',
        }
      }
    },
    watch:{
      dialog:{
        handler(val){
          if (!val.show){

            this.loading = false
            this.lot = {
                unit:'Choose Your Unit',
              image:'',
              video:'',
              siteplan:'',
              spots:''
            }
          } else if (val.type === 'add'){
              this.lot.shapeId = this.pointInfo.point.id
              this.lot.shape = this.pointInfo.point
          } else if (val.type === 'edit'){
            let obj = JSON.parse(JSON.stringify(this.$store.state.condogrid.editingLot))
            console.log('obj', obj)

            if (typeof (obj.units[0]) === 'object') {
              console.log('truee')
              obj.unit = obj.units[0].name
            }
            else {
              obj.unit = obj.units[0]
            }


            this.lot = obj
            console.log('thislot', this.lot)
          }
          this.newTime = Date.now() + ''

        },
        deep:true
      }
    },
    computed:{
        lots(){
          return Object.values(this.$store.getters.lots).map( ({name,id:value}) => ({
            name,value
          }))
        },
        pointInfo(){
            return this.$store.state.condogrid.newLotInfo
        },
        tags(){
          return this.$store.state.condogrid.tags
        },
        instance(){
          return this.$store.state.instance
        },
        appData(){
          return this.$store.state.condogrid.appData
        },
        getTitle(){
          return `Create a new `
        },
        dialog(){
          return this.$store.state.condogrid.lotDrawer
        },
        storeUnit (){
          return Object.values(this.$store.state.condogrid.units)
        },
        units(){
          let unitList = []
          let units = Object.values(this.$store.state.condogrid.units)

          for (var i=0; i<units.length; i++) {
            if (units[i].unitGroup && units[i].unitGroup.id == this.currentFloor.id) {
              console.log('groupp', this.currentFloor.id)
              unitList.push(units[i])
            }
          }
          return unitList
        },
        currentFloor() {
          return this.$store.state.condogrid.currentFloor
        },
        floors(){
        return this.$store.state.condogrid.floors || []
        }
    },
    methods: {
      deleteLot() {
        let self = this
        this.$confirm({
        title: "Delete",
        content: h => <div>Do you want to delete this unit?</div>,
        okText: 'Delete',
        okType: 'danger',
        cancelText: 'Cancel',
        centered: true,
        async onOk() {
          let siteplanId
          Object.values(self.floors).forEach(floor => {
            floor.siteplan.lots.forEach(lot => {
              if (lot.id == self.lot.id) {
                siteplanId = floor.siteplan.id
              }
            })
          })

          self.$api.delete(`/lots/${self.instance.id}/${siteplanId}/${self.lot.id}`).then( ({data}) => {
            self.$store.dispatch('CLOSE_DRAWER', {type:'delete', data})
            // $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
          }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					self.$message.error(self.$err(err))
				}
			})
        },
        onCancel() {
            console.log('Cancel')
        }
        })
      },
      selectImage(item){
        this.lot.image = item
      },
      selectVideo(item){
        this.lot.video = item
      },
      updateUnits(e){
        console.log('EEEEEEE',e)
        this.lotUnitType = e
        this.lot.units = []
      },
      formatNumber,
      deletePremium(id){
        this.lot.premiums = this.lot.premiums.filter(x => x.id !== id)
      },
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
        );
      },
      addNewPremium(){
        if (!this.newPremium.name.trim()) return this.$message.error('Premium Requires a name')
        if (this.newPremium.value !== 0 && !parseFloat(this.newPremium.value)) return this.$message.error('Price has to be a Number')
        this.lot.premiums.push({
          id:Date.now() + '',
          name:this.newPremium.name,
          value:this.newPremium.value
        })
        this.newPremium = {
          name:'',
          value:0,
          show:false
        }

      },
      onUnitSelect(val){
        this.lot.units = val
      },
      confirmDelete(){
        this.deleting = true
        this.$api.delete(`/lots/${this.instance.id}/${this.$store.state.condogrid.currentFloor}/${this.lot.id}`)
          .then( ({data}) => this.onClose({type:'delete', data}))
		  .catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
      },
      submitButton(){
        if (this.dialog.type === 'add') return this.createLot()
        return this.updateLot()
      },
      tagsToIds(obj){

        let tags = obj.tags || []

        obj.newTags = []
        let oldTags = []

        let self = this

        tags.forEach(tID => {
          if (self.tags.find(x => x.id === tID)) oldTags.push(tID)
          else obj.newTags.push(tID)
        })
        obj.tags = oldTags

        return obj

      },
      createLot(){
        if (this.lot.unit == 'Choose Your Unit') return this.$message.error('Assign a Unit to this Lot')
        if (!this.lot.image.trim()) return this.$message.error('Lot Needs an Image')
        console.log('current',this.$store.state.condogrid.currentFloor)
        let sendObj = JSON.parse(JSON.stringify(this.lot))

        sendObj = this.tagsToIds(sendObj)
        sendObj.spots = this.pointInfo.settings.spots
        sendObj.siteplan = this.$store.state.condogrid.currentFloor.siteplan.id
        sendObj.units = [sendObj.unit]

        this.$store.commit('ADD_LOTS', sendObj.spots)
        this.loading = true
        this.$api.post(`/lots/${this.instance.id}/${sendObj.siteplan}`, sendObj).then( ({data}) => {
          console.log('data',data)
          this.loading = false
          this.onClose({type:'add',data})
        }).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		}).finally(() => {
			this.loading = false
		})

      },
      updateLot(){

        if (this.lot.unit == 'Choose Your Unit') return this.$message.error('Assign a Unit to this Lot')
        if (!this.lot.image.trim()) return this.$message.error('Lot Needs an Image')

        let sendObj = JSON.parse(JSON.stringify(this.lot))

        sendObj = this.tagsToIds(sendObj)
        sendObj.units = [sendObj.unit]
        this.loading = true
        this.$api.put(`/lots/${this.instance.id}/${sendObj.siteplan}/${sendObj.id}`, sendObj).then( ({data}) => {
          this.loading = false
          this.onClose({type:'edit',data})
        }).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})

      },
      onClose({type='',data={}}) {
        console.log('CLOSING =>', type,data)
        if (this.dialog.type === 'add' && (!type || type === 'click')){
            let {settings,self,point} = this.pointInfo

            let index = settings.spots.findIndex(x => x.id === point.id)
            console.log('DELEETINGGGGG', index)
            settings.spots.splice(index,1)
            self.addAction();
            self.redraw();
        }
        this.$store.dispatch('CLOSE_DRAWER',{type,data})
        $('[data-wcp-editor-toolbar-button-name=select]').click()
      },
    },
  };
</script>
<style>
.cancel-button.ant-btn {
  border-color:#ECE9F1 !important;
  background-color:#ECE9F1;
  color:#3F3356
}
</style>

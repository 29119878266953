<template>
    <div>

        <a-modal centered :visible="siteplanRulesModal" :confirmLoading="loadModal" :footer="null"  :width="600" :title="null" :closable="false" class="amenity-dialog" :class="loadModal? 'amenity-modal-loading' : ''" :bodyStyle="{padding:0}">
            <a-tabs v-model="activeTab">
                <a-tab-pane tab="Tab 1" key="1">
                    <div>
                        <img src="https://images2.minutemediacdn.com/image/upload/c_crop,h_841,w_1500,x_0,y_132/f_auto,q_auto,w_1100/v1554995044/shape/mentalfloss/istock-531327152.jpg" style="max-width:100%"/>
                    </div>
                    <div class="px-5 py-3 mt-3">
                        <h3>Same model side by side?</h3>
                            <a-form>
                                <a-form-item label="How many times can the same Model reoccur side by side?">
                                    <a-select v-model="control.modelSide" style="width: 100%">
                                        <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                            {{i === 1? 'None' : `${i} in a row`}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                        </div>

                        <div>
                        <a-button type="primary" @click="activeTab = '2'">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="2">
                    <div>
                        <img src="https://ichef.bbci.co.uk/wwfeatures/live/976_549/images/live/p0/7d/9z/p07d9znv.jpg" style="max-width:100%"/>
                    </div>
                    <div class="px-5 py-3 mt-3">
                        <h3>Number of <strong>Models</strong> per 10 building?</h3>
                            <a-form>
                                <a-form-item label="Choose an answer Below">
                                    <a-select v-model="control.modelPer10" style="width: 100%">
                                        <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                            {{i === 1? 'Only one' : `${i} out of 10`}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="3">
                    <div>
                        <img src="https://ichef.bbci.co.uk/wwfeatures/live/976_549/images/live/p0/7d/9z/p07d9znv.jpg" style="max-width:100%"/>
                    </div>
                    <div class="px-5 py-3 mt-3">
                        <h3>Lot space between identical elevations?</h3>
                        <a-form>
                            <a-form-item label="Choose an answer Below">
                                <a-select v-model="control.sameElevLotSpaces" style="width: 100%">
                                    <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                                    {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="4">
                    <div>
                        <img src="https://ichef.bbci.co.uk/wwfeatures/live/976_549/images/live/p0/7d/9z/p07d9znv.jpg" style="max-width:100%"/>
                    </div>
                    <div class="px-5 py-3 mt-3">
                        <h3>Number of <strong>Elevations</strong> per 10 building?</h3>
                        <a-form>
                            <a-form-item label="Choose an answer Below">
                                <a-select v-model="control.elevPer10" style="width: 100%">
                                    <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                    {{i === 1? 'Only one' : `${i} out of 10`}}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="5">
                    <div>
                        <img src="https://ichef.bbci.co.uk/wwfeatures/live/976_549/images/live/p0/7d/9z/p07d9znv.jpg" style="max-width:100%"/>
                    </div>
                    <div class="px-5 py-3 mt-3">
                        <h3>Same Elevation in a Streetscape (%)</h3>
                        <a-form>
                            <a-form-item label="The maximum percentage of Occurance of the same Elevation">
                                <a-select v-model="control.elevStreetPercentage" style="width: 100%">
                                    <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                    {{i * 10}} %
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="6">
                    <div>
                        <img src="https://images2.minutemediacdn.com/image/upload/c_crop,h_841,w_1500,x_0,y_132/f_auto,q_auto,w_1100/v1554995044/shape/mentalfloss/istock-531327152.jpg" style="max-width:100%"/>
                    </div>
                    <div class="px-5 py-3 mt-3">
                        <h3>Can identical Elevation be across each other?</h3>
                            <a-form>
                                <a-form-item >
                                    <a-select v-model="control.sameElevAcross" style="width: 100%">
                                        <a-select-option :value="1" >Yes</a-select-option>
                                        <a-select-option :value="0" >No</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="7">
                    <div>
                        <img src="https://images2.minutemediacdn.com/image/upload/c_crop,h_841,w_1500,x_0,y_132/f_auto,q_auto,w_1100/v1554995044/shape/mentalfloss/istock-531327152.jpg" style="max-width:100%"/>
                    </div>
                    <div class="px-5 py-3 mt-3">
                        <h3>Lot space between identical Brick package?</h3>
                            <a-form>
                                <a-form-item label="Choose an answer Below">
                                    <a-select v-model="control.sameBrickLotSpaces" style="width: 100%">
                                        <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                                        {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="8">
                    <div>
                        <img src="https://images2.minutemediacdn.com/image/upload/c_crop,h_841,w_1500,x_0,y_132/f_auto,q_auto,w_1100/v1554995044/shape/mentalfloss/istock-531327152.jpg" style="max-width:100%"/>
                    </div>
                    <div class="px-5 py-3 mt-3">
                        <h3>Lot space between identical Elevation &amp; identical Brick package?</h3>
                            <a-form>
                                <a-form-item label="Choose an answer Below">
                                    <a-select v-model="control.sameElevAndBrickLotSpaces" style="width: 100%">
                                        <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                                        {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                                        </a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" @click="activeTab = (parseInt(activeTab) + 1) + ''">Next <a-icon type="arrow-right"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>
                <a-tab-pane tab="Tab 2" key="9">
                    <div>
                        <img src="https://images2.minutemediacdn.com/image/upload/c_crop,h_841,w_1500,x_0,y_132/f_auto,q_auto,w_1100/v1554995044/shape/mentalfloss/istock-531327152.jpg" style="max-width:100%"/>
                    </div>
                    <div class="px-5 py-3 mt-3">
                        <h3>Can identical Brick Package be across each other?</h3>
                            <a-form>
                                <a-form-item >
                                    <a-select v-model="control.sameBrickAcross" style="width: 100%">
                                        <a-select-option :value="1" >Yes</a-select-option>
                                        <a-select-option :value="0" >No</a-select-option>
                                    </a-select>
                                </a-form-item>
                            </a-form>
                    </div>
                    <div class="w-full px-3 pb-3 mt-3 dF jSB">
                        <div>
                            <a-button :disabled="loadModal" type="primary" @click="activeTab = (parseInt(activeTab) - 1) + ''"><a-icon type="arrow-left"/> Prev</a-button>
                        </div>

                        <div>
                            <a-button type="primary" :disabled="loadModal" @click="applyRules">Apply Rules <a-icon :type="loadModal? 'loading' : 'arrow-right'"  /></a-button>
                        </div>
                    </div>
                </a-tab-pane>




            </a-tabs>


        </a-modal>
        <a-drawer
            :zIndex="1000"
            title="Edit Siteplan Rules"
            :width="720"
            @close="onClose"
            :visible="siteplanRulesDrawer"
            :wrapStyle="{height: 'calc(100% - 50px)',overflow: 'auto'}"
            >
            <!-- <bh-loading :show="loading" /> -->
            <a-form  layout="vertical" class="h-full dF fC jSB">

                <div class="f1">
                    <a-row :gutter="16">
                        <h5>Same model side by side?</h5>
                        <a-form-item label="How many times can the same Model reoccur side by side?">
                            <a-select v-model="control.modelSide" style="width: 100%">
                                <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                    {{i === 1? 'None' : `${i} in a row`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                       <hr />

                       <h5>Number of <strong>Models</strong> per 10 building?</h5>
                        <a-form-item label="Choose an answer Below">
                            <a-select v-model="control.modelPer10" style="width: 100%">
                                <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                    {{i === 1? 'Only one' : `${i} out of 10`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Lot space between identical elevations?</h5>
                        <a-form-item label="Choose an answer Below">
                            <a-select v-model="control.sameElevLotSpaces" style="width: 100%">
                                <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                                {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Number of <strong>Elevations</strong> per 10 building?</h5>
                        <a-form-item label="Choose an answer Below">
                            <a-select v-model="control.elevPer10" style="width: 100%">
                                <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                {{i === 1? 'Only one' : `${i} out of 10`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Same Elevation in a Streetscape (%)</h5>
                        <a-form-item label="The maximum percentage of Occurance of the same Elevation">
                            <a-select v-model="control.elevStreetPercentage" style="width: 100%">
                                <a-select-option v-for="i in 5" :value="i" :key="`modelsidebyside${i}`">
                                {{i * 10}} %
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Can identical Elevation be across each other?</h5>
                        <a-form-item >
                            <a-select v-model="control.sameElevAcross" style="width: 100%">
                                <a-select-option :value="1" >Yes</a-select-option>
                                <a-select-option :value="0" >No</a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Lot space between identical Brick package?</h5>
                        <a-form-item label="Choose an answer Below">
                            <a-select v-model="control.sameBrickLotSpaces" style="width: 100%">
                                <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                                {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Lot space between identical Elevation &amp; identical Brick package?</h5>
                        <a-form-item label="Choose an answer Below">
                            <a-select v-model="control.sameElevAndBrickLotSpaces" style="width: 100%">
                                <a-select-option v-for="i in 10" :value="i" :key="`modelsidebyside${i}`">
                                {{i === 1? '1 Lot Space' : `${i} Lot Spaces`}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>

                        <hr />

                        <h5>Can identical Brick Package be across each other?</h5>
                        <a-form-item >
                            <a-select v-model="control.sameBrickAcross" style="width: 100%">
                                <a-select-option :value="1" >Yes</a-select-option>
                                <a-select-option :value="0" >No</a-select-option>
                            </a-select>
                        </a-form-item>




                    </a-row>


                </div>

                <div
                :style="{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }"
                >
                <div class="dF jSB">
                    <div>

                    </div>
                    <div>
                        <a-button :style="{marginRight: '8px'}" @click="onClose">
                        Cancel
                        </a-button>
                        <a-button  type="primary" class="btn-purple" @click="updateRules">Update Rules</a-button>
                    </div>
                </div>

                </div>

            </a-form>

        </a-drawer>

    </div>

</template>

<script>
export default {
    data(){
        return {
            activeTab:'1',
            loadModal:false,
            control:{
                modelSide:1,
                modelPer10:1,
                sameElevLotSpaces:1,
                elevPer10:1,
                elevStreetPercentage:10, //10-50
                sameElevAcross:1,
                sameBrickLotSpaces:1,
                sameElevAndBrickLotSpaces:1,
                sameBrickAcross:0
            }

        }
    },
    watch:{
        siteplanRulesModal(val){
            if (!val) this.loadModal = false
            this.activeTab = '1'
            this.control = {
                modelSide:1,
                modelPer10:1,
                sameElevLotSpaces:1,
                elevPer10:1,
                elevStreetPercentage:10, //10-50
                sameElevAcross:1,
                sameBrickLotSpaces:1,
                sameElevAndBrickLotSpaces:1,
                sameBrickAcross:0
            }
        },
        siteplanRulesDrawer(val){
            if (val){
                let control = JSON.parse(JSON.stringify(this.$store.state.siteplan.rules))
                control.sameElevAcross = control.sameElevAcross? 1 : 0
                control.sameBrickAcross = control.sameBrickAcross? 1 : 0
                this.control = control
                console.log('CONTROLLLL', this.control.id)
            } else {
                this.control = {
                modelSide:1,
                modelPer10:1,
                sameElevLotSpaces:1,
                elevPer10:1,
                elevStreetPercentage:10, //10-50
                sameElevAcross:1,
                sameBrickLotSpaces:1,
                sameElevAndBrickLotSpaces:1,
                sameBrickAcross:0
            }
            }
        }
    },
    computed:{
        siteplanRulesDrawer(){
            return this.$store.state.siteplan.siteplanRulesDrawer
        },
        siteplanRulesModal(){
            return this.$store.state.siteplan.siteplanRulesModal
        },
        siteplan(){
            return this.$store.state.siteplan.appData
        },

    },
    methods:{
        updateRules(){
            console.log('upDATING RULES')
            let self = this
            this.loadModal = true
            this.$api.put(`/architectural-controls/${this.$store.state.instance.id}/${this.siteplan.id}/${this.control.id}`, this.control).then( ({data}) => {
                self.$store.commit('APPLY_RULES', data)
                self.$store.commit('CLOSE_SETTINGS')
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					self.$message.error(self.$err(err))
				}
			})
        },
        onClose(){
            this.$store.commit('CLOSE_SETTINGS')
        },
        applyRules(){
            let self = this
            this.loadModal = true
            this.$api.post(`/architectural-controls/${this.$store.state.instance.id}/${this.siteplan.id}`, this.control).then( ({data}) => {
                self.$store.commit('APPLY_RULES', data)
                self.$store.commit('CLOSE_SETTINGS')
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					self.$message.error(self.$err(err))
				}
			})
        }
    }
}
</script>

<style>

</style>

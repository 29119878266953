<template>
    <div v-if="img">
        <img class="image-holder" :src="img" style="width:85%; height:200px; border: solid 1px; border-color:var(--light-gray); border-radius: 5px; object-fit:cover" />
        <span style="position:absolute; margin-left:-20px"><i @click="$store.commit('MEDIA_SELECT',{callback:selectImage,type:'images'})" class="fe fe-edit-2" style="border-radius:50%; border: solid 1px; border-color:var(--light-gray) ;background-color:white; font-size:20px; padding:7px; color:var(--orange)" /></span>
    </div>
    <div v-else>
        <div class="image-holder" style="background-color:var(--off-white-dark); border:dashed 1px  #d9d9d9; height:200px; width:85%; border-radius:5px" @click="$store.commit('MEDIA_SELECT',{callback:selectImage,type:'images'})">
            <ul style="list-style-type:none; text-align:center;padding:0px;" class="mt-5 mb-5">

                <li>
                <a-icon type="plus" style="color:white; font-size:25px; background-color:var(--light-gray); padding:10px; border-radius:50px" />
                </li>
                <li class="mt-5">
                    <h5>{{message}}</h5>
                </li>
            </ul>
  
        </div>
    </div>
</template>

<script>


export default {
    props:{
        img:{
            default:'',
            type: String
        },
        message:{
            default:'',
            type: String
        }
    },
    data() {
        return{
            newImg:''
        }
    },
    watch:{
        newImg(val){
     
            let self = this
            let ext = val.split('.')[val.split('.').length -1] 

            let img = document.createElement('img');
            img.onload = () => {
                console.log('width => ',img.naturalWidth);
                console.log('height => ',img.naturalHeight);
                let width = img.naturalWidth
                let height = img.naturalHeight
                if (ext !== 'svg' && (!width || !height) ) {
                    self.img = ''
                    return self.$message.error('Image Error. Not a Valid Image')
                }
                // self.$emit('input',val)
                self.$emit('sized',{ext,width,height})
            }
            img.src = val;
            


        }
    },
    methods:{
        selectImage(url) {
            this.newImg = url.url
            this.$emit('callback', this.newImg)
        }
    }
}
</script>

<style scoped>
.image-holder:hover{
      border-color: var(--orange);
      transition: border-color .3s ease;
  }
</style>
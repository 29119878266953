<template>
    <a-drawer
        :zIndex="1000"
        :title="dialog.type === 'add'? 'Add New Amenity' : 'Edit Amenity'"
        :width="720"
        @close="onClose"
        :visible="dialog.show"
        :wrapStyle="{overflow: 'auto'}"
        :maskClosable="false"
        >
        <bh-loading :show="loading" />
        <a-form-model class="h-full df fC jSB" ref="newAmenity" :model="newAmenity">
            <div class="f1">
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item prop="name" label="Amenity Name" required :rules="req('Please enter the Amenity Name')">
                            <a-input v-model="newAmenity.name" placeholder="Example: Gym"></a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item label="Description">
                            <a-textarea
                                placeholder="Description of the Amenity"
                                :auto-size="{ minRows: 5, maxRows: 6 }"
                                v-model="newAmenity.description"
                                />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                    <a-form-model-item label="Featured Image" required>
                        <div style="width:50%">
                        <ImageBox :type=2 :img="newAmenity.image" @callback="selectImage" />
                        </div>
                        <!-- <a-row :gutter="16" class="mr-3">
                        <a-col :span="20">
                            <a-input :disabled="true" v-model="newAmenity.image"></a-input>
                            <p>Minimum upload file size: 1MB.</p>
                        </a-col>
                        <a-col :span="4">
                            <a-button @click="$store.commit('MEDIA_SELECT',{callback:selectImage,type:'images'})" style="background-color:var(--light-gray)"><a-icon type="plus" />ADD IMAGE</a-button>
                        </a-col>
                        </a-row> -->
                    </a-form-model-item>

                    <a-form-model-item label="Media">
                        <div style="width:50%">
                        <ImageBox :type=2 :img="newAmenity.video" @callback="selectVideo" />
                        </div>
                        <!-- <a-row :gutter="16" class="mr-3">
                        <a-col :span="20">
                            <a-input :disabled="true" v-model="newAmenity.video"></a-input>
                            <p>Minimum upload file size: 5MB.</p>
                        </a-col>
                        <a-col :span="4">
                            <a-button @click="$store.commit('MEDIA_SELECT',{callback:selectVideo,type:'video'})" style="background-color:var(--light-gray)"><a-icon type="plus" />ADD IMAGE</a-button>
                        </a-col>
                        </a-row> -->
                    </a-form-model-item>
            </div>
             <div
                :style="{
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    borderTop: '1px solid #e9e9e9',
                    padding: '10px 16px',
                    background: '#fff',
                    textAlign: 'right',
                }"
                >
                <div class="dF jSB">
                <div>
                    <a-button size="large" @click="deleteAmenity" v-if="dialog.type === 'edit'" type="danger" ghost icon="delete">DELETE</a-button>
                </div>
                <div>
                    <a-button size="large" class="mr-3 cancel-button" @click="onClose">
                    CANCEL
                    </a-button>
                    <a-button type="primary" size="large" @click="submitButton">{{dialog.type === 'add'? 'CREATE' : 'UPDATE'}}</a-button>
                </div>
                </div>

            </div>
        </a-form-model>

    </a-drawer>
</template>

<script>
import ImageBox from '@/components/siteplan/ImageBox'
import bhLoading from 'bh-mod/components/common/Loading'
export default {
    components:{
        ImageBox, bhLoading
    },
    data() {
        return{
            newAmenity:{
                name:'',
                description:'',
                image:'',
                video:'',
                type:'info',
                siteplan:'',
                spots:'',
            },
            loading:false
        }
    },
    computed: {
        dialog(){
          return this.$store.state.condogrid.amenityDrawer
        },
        pointInfo(){
            return this.$store.state.condogrid.newAmenityInfo
        },
        tags(){
          return this.$store.state.condogrid.tags
        },
        instance(){
          return this.$store.state.instance
        },
        appData(){
          return this.$store.state.condogrid.appData
        },
        floors(){
        return this.$store.state.condogrid.floors || []
        },
    },
    watch:{
        dialog:{
            handler(val){
            if (!val.show){
                this.newAmenity = {
                    name:'',
                    description:'',
                    image:'',
                    video:'',
                    type:'info',
                    siteplan:'',
                    spots:''
                    }
            } else if (val.type === 'add'){
                this.newAmenity.shapeId = this.pointInfo.point.id
                this.newAmenity.shape = this.pointInfo.point
            } else if (val.type === 'edit'){
                let obj = JSON.parse(JSON.stringify(this.$store.state.condogrid.editingAmenity))


                // let found = this.storeUnit.find(unit => {
                //   return unit.id == obj.units[0]
                // })
                // obj.units = found.name


                // if (Array.isArray(obj.units)){
                //   if (typeof obj.units[0] === 'object')
                //     obj.units = obj.units[0].id
                //   else obj.units = obj.units[0]
                // }
                this.newAmenity = obj
            }
            this.newTime = Date.now() + ''

            },
            deep:true
        }
    },
    methods:{
        deleteAmenity(){
            let self = this
          this.$confirm({
          title: "Delete",
          content: h => <div>Do you want to delete this amenity?</div>,
          okText: 'Delete',
          okType: 'danger',
          cancelText: 'Cancel',
          centered: true,
          async onOk() {
            let siteplanId
            Object.values(self.floors).forEach(floor => {
              floor.siteplan.lots.forEach(lot => {
                if (lot.id == self.newAmenity.id) {
                  siteplanId = floor.siteplan.id
                }
              })
            })
            self.$api.delete(`/lots/${self.instance.id}/${siteplanId}/${self.newAmenity.id}`).then( ({data}) => {
              self.$store.dispatch('CLOSE_DRAWER', {type:'delete', data})
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					self.$message.error(self.$err(err))
				}
			})
          },
          onCancel() {
              console.log('Cancel')
          }
          })
        },
        req:msg=>({required:true,message:msg}),
        onClose({type='', data={}}) {
            console.log('closing')
            if (this.dialog.type === 'add' && (!type || type === 'click')){
                let {settings,self,point} = this.pointInfo

                let index = settings.spots.findIndex(x => x.id === point.id)
                console.log('DELEETINGGGGG', index)
                settings.spots.splice(index,1)
                self.addAction();
                self.redraw();
            }
            this.$store.dispatch('CLOSE_DRAWER',{type,data})
            $('[data-wcp-editor-toolbar-button-name=select]').click()
        },
        submitButton() {
            if(this.dialog.type === 'add') return this.createAmenity()
            return this.updateAmenity()
        },
        tagsToIds(obj){

            let tags = obj.tags || []

            obj.newTags = []
            let oldTags = []

            let self = this

            tags.forEach(tID => {
            if (self.tags.find(x => x.id === tID)) oldTags.push(tID)
            else obj.newTags.push(tID)
            })
            obj.tags = oldTags

            return obj

        },
        createAmenity() {
            console.log('creating', this.newAmenity)
            if (!this.newAmenity.name.trim()) return this.$message.error('Amenity Needs a Name')
            if (!this.newAmenity.image.trim()) return this.$message.error('Amenity Needs an Image')


            let sendObj = JSON.parse(JSON.stringify(this.newAmenity))

            sendObj = this.tagsToIds(sendObj)
            sendObj.spots = this.pointInfo.settings.spots
            sendObj.siteplan = this.$store.state.condogrid.currentFloor.siteplan.id

            this.$store.commit('ADD_LOTS', sendObj.spots)
            this.loading = true
            this.$api.post(`/lots/${this.instance.id}/${sendObj.siteplan}`, sendObj).then( ({data}) => {
                console.log('data', data)
                this.loading = false
                this.onClose({type:'add',data})
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        updateAmenity() {
            console.log('updating')
            if (!this.newAmenity.name.trim()) return this.$message.error('Amenity Needs a Name')
            if (!this.newAmenity.image.trim()) return this.$message.error('Amenity Needs an Image')

            let sendObj = JSON.parse(JSON.stringify(this.newAmenity))

            sendObj = this.tagsToIds(sendObj)
            sendObj.siteplan = this.$store.state.condogrid.currentFloor.siteplan.id
            this.loading = true
            this.$api.put(`/lots/${this.instance.id}/${sendObj.siteplan}/${sendObj.id}`, sendObj).then( ({data}) => {
                this.loading = false
                this.onClose({type:'edit',data})
            }).catch(err => {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err))
				}
			})
        },
        selectImage(item) {
            this.newAmenity.image = item
        },
        selectVideo(item) {
            this.newAmenity.video = item
        }
    }
}
</script>

<style>
.cancel-button.ant-btn {
  border-color:#ECE9F1 !important;
  background-color:#ECE9F1;
  color:#3F3356
}
</style>

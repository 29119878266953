<template>
  <a-drawer
        v-if="false"
      :zIndex="1000"
      :title="dialog.type === 'add'? 'Create new Unit' : 'Edit Unit'"
      :width="720"
      @close="onClose"
      :visible="dialog.show"
    >
      <bh-loading :show="loading" />
      <a-form  layout="vertical" class="h-full dF fC jSB">

        <div class="f1">
          <a-row :gutter="16">
            <a-col :span="8">
                <a-form-item label="Floor Name" required>
                    <a-input v-model="floor.name" placeholder="Floor Name" >
                    </a-input>
                </a-form-item>
            </a-col>


          </a-row>

          <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-item label="Floor Base image" >
                        <image-box-selector  :key="newTime" v-model="floor.image" type="images" @sized="updateSize" />
                    </a-form-item>
                </a-col>

          </a-row>
          <a-row :gutter="16" v-if="false">
                <a-col :span="12">
                    <a-button @click="resetShapes" icon="delete" type="primary">Clear Lots &amp; Shapes</a-button>
                </a-col>

          </a-row>




        </div>

        <div
          :style="{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }"
        >
        <div class="dF jSB">
            <div>
                <a-popover title="Deleting Floor" trigger="click" v-model="deletePopup">
                    <div slot="content" v-if="floor.order !== 1">
                        Are you sure? This will delete this Floor
                        <div class="mt-3">
                            <a-button :style="{marginRight: '8px'}" @click="deletePopup = false" :disabled="deleting">
                                No
                            </a-button>
                            <a-button  @click="confirmDelete" type="danger" :disabled="deleting" :icon="deleting? 'loading' : ''">
                                Yes
                            </a-button>
                        </div>
                    </div>
                    <div slot="content" v-else>
                        You cannot delete the First Floor.
                    </div>
                    <a-button :disabled="deleting" @click="deletePopup = true" v-if="dialog.type === 'edit'" type="danger" ghost icon="delete">
                        Delete Floor
                    </a-button>
                </a-popover>

            </div>
          <div>
            <a-button :style="{marginRight: '8px'}" @click="onClose">
              Cancel
            </a-button>
            <a-button  type="primary" class="btn-purple" @click="submitButton">{{dialog.type === 'add'? 'Create' : 'Update'}} Floor</a-button>
          </div>
        </div>

        </div>

      </a-form>

    </a-drawer>





    <a-drawer
        v-else
        :visible="floorSettings"
        :zIndex="1000"
        :width="'50%'"
        :wrapStyle="{overflow: 'auto'}"
        @close="closeSettings"
        :maskClosable="false"
        >
        <template slot="title">
            <div>Floor Settings <small class="ml-5" style="color:var(--gray)">Insert/edit floor plate image</small></div>
        </template>
        <a-row :gutter="16">
            <a-col :span="8" v-for="floor in floors" :key="floor.id" class="mb-5">
                <h5>{{floor.name}}</h5>
                <ImageHolder @sized="(e) => imgSized(e, floor.id)" message="Add Image" :img="floor.siteplan.image" @callback="(img) => selectImage(img, floor.id)"/>


                <!-- <ImageBox :image="!floorImage[floor.id]? `` : `${floorImage[floor.id].image}`" @callback=" (img) => selectImage(img, floor.id)"/> -->
            </a-col>

            <!-- <img :src="floor.siteplan.image" /> -->

        </a-row>
        <div
            :style="{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
            }"
            >
            <a-button @click="closeSettings" size="large" class="cancel-button">CANCEL</a-button>
            <a-button @click="updateSettings" type="primary" size="large" class="ml-3">UPDATE</a-button>
        </div>
    </a-drawer>
</template>

<script>
  import bhLoading from 'bh-mod/components/common/Loading'
  import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
  import ImageHolder from '@/components/siteplan/ImageHolder'
  export default {
    components:{bhLoading,ImageBoxSelector, ImageHolder},
    data(){
        return {
            floorImage:{},
            newTime:Date.now(),
            loading:false,
            deleting:false,
            deletePopup:false,
            floor:{
                name:'',
                image:'',
                width:0,
                height:0
            }
        }
    },
    watch:{
        dialog:{
            handler(val){
                if (!val.show){
                    this.loading = false,
                    this.deleting = false,
                    this.deletePopup = false,
                    this.floor = {
                        name:'',
                        image:'',
                    }
                } else if (val.type === 'edit'){
                    this.floor = JSON.parse(JSON.stringify(this.editingFloor))
                }
                this.newTime = Date.now()
            },
            deep:true
        }
    },
    computed:{
        floorSettings() {
            return this.$store.state.condogrid.floorSettings
        },
        dialog(){
            return this.$store.state.condogrid.floorDrawer
        },
        editingFloor(){
            return this.$store.getters.editingFloor
        },
        instance(){
            return this.$store.state.instance
        },
        floors() {
            let floors = Object.values(this.$store.state.condogrid.floors).sort((a,b) => {
                if (a.order > b.order) return 1
                else if (a.order < b.order) return -1
                else return 0
            })
            return floors
        },
        currentFloor () {
            return this.$store.state.condogrid.currentFloor
        }
    },
    methods:{
        selectImage(img, id) {
           let floor = this.floors.find(floor => floor.id === id)
           floor.siteplan.image = img
        },
        closeSettings() {
            this.$store.commit('CLOSE_ADD_FLOOR')
        },
        updateSettings() {
            this.floors.forEach(floor => {
                this.$api.put(`/siteplans/:instance/${floor.siteplan.id}`, floor.siteplan).then(({data}) => {
                    this.$store.commit('UPDATE_FLOOR', data)
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
            })


        },
        imgSized({width,height}, id) {
            let floor = this.floors.find(floor => floor.id === id)
            floor.siteplan.width = width
            floor.siteplan.height = height
        },

        updateSize({ext,width,height}){
            this.floor.width = width
            this.floor.height = height
        },
        confirmDelete(){
            if (this.floor.order === 1) return this.$message.error('First Floor Cannot be Deleted.')
            this.$api.delete(`/condo-grids/${this.instance.id}/${this.$store.state.condogrid.appData.id}/${this.floor.id}`).then( ({data}) => {
                this.loadModal = false
                this.onClose({type:'delete',data})
            }).catch( err => {
                let error = this.$err(err)
                this.$message.error(error)
            })
            this.onClose({type:'delete',data:this.floor})
        },
        submitButton(){
            if (this.dialog.type === 'add') return this.createFloor()

            return this.updateFloor()
        },
        createFloor(){
            if (!this.floor.image) return this.$message.error('Please choose a Base Image')
            this.$api.post(`/condo-grids/${this.instance.id}/${this.$store.state.condogrid.appData.id}`, this.floor).then( ({data}) => {
                this.loadModal = false
                this.onClose({type:'add',data})
            }).catch( err => {
                let error = this.$err(err)
                this.$message.error(error)
            })

        },
        updateFloor(){
            if (!this.floor.image) return this.$message.error('Please choose a Base Image')
            this.loadModal = true

            this.$api.put(`/siteplans/${this.instance.id}/${this.floor.id}`, this.floor).then( ({data}) => {
                this.loadModal = false
                this.onClose({type:'edit',data})
            }).catch( err => {
                let error = this.$err(err)
                this.$message.error(error)
            })

        },
        resetShapes(){

        },
        onClose({type='',data={}}) {
            this.$store.dispatch('CLOSE_FLOOR_DRAWER',{type,data})
        },
    }
  };
</script>
<style>
.cancel-button.ant-btn {
  border-color:#ECE9F1 !important;
  background-color:#ECE9F1;
  color:#3F3356
}
</style>

<template>
    <div style="width:100%; background:#F7F5F9">
        <!-- <a-modal
            title="Siteplan Base Image"
            :visible="siteplanSettings"
            @ok="changeImage"
            :confirmLoading="loadModal"
            @cancel="$store.commit('CLOSE_SETTINGS')"
            >
            <div class="dF fC jC aC">
                <h5 class="mt-3 mb-3 text-center" v-if="!siteplan.image">Please choose a Keymap Image for this Floor</h5>
                <h5 class="mt-3 mb-3 text-center" v-else>Please click below to change the Keymap Image for this Floor</h5>
                <image-box-selector  v-model="baseImage" type="images" class="mt-5"/>
                <img :src="baseImage" width="0" height="0" class="baseImage-change" />
            </div>

        </a-modal> -->
        <LotDrawer />
        <AmenityDrawer />
        <FloorDrawer />
        <div id="wcp-editor" dat-img></div>

    </div>
</template>

<script>
    import {getBase64} from 'bh-mod'
    import LotDrawer from '@/components/siteplan/LotDrawer'
    import AmenityDrawer from '@/components/siteplan/AmenityDrawer'
    import FloorDrawer from '@/components/siteplan/FloorDrawer'
    import SiteplanRules from '@/components/siteplan/SiteplanRules'
    import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'

    export default {
        components:{
            LotDrawer,ImageBoxSelector,SiteplanRules,FloorDrawer,AmenityDrawer
        },
        data() {
            return {
                filter:'all',
                baseImage:'',
                loadModal:false,
            }
        },
        watch:{
          
            // siteplanSettings(val){
            //     if (val){
            //         this.baseImage = this.$store.getters.currentFloor.image || ''
            //     }
            // },
            currentFloor:{
                handler(newVal, oldVal){
                    console.log('IMAGE UPDATE', newVal.siteplan.image, oldVal.siteplan.image)
                    console.log('SITEPLAN UPDATED', newVal.id, oldVal.id)
                    if (oldVal.id !== newVal.id){
                        $.imageMapProEditorDefaults.spots = newVal.siteplan.shapes || [];
                        if (!newVal.siteplan.image) this.$store.commit('SITEPLAN_IMAGE_SETTINGS')
                        else {
                            
                            $.imageMapProEditorDefaults.image.url = newVal.siteplan.image
                            $.imageMapProEditorDefaults.general.width = newVal.siteplan.width
                            $.imageMapProEditorDefaults.general.height = newVal.siteplan.height

                            $.imageMapProEditorDefaults.general.naturalWidth = $.imageMapProEditorDefaults.general.width;
                            $.imageMapProEditorDefaults.general.naturalHeight = $.imageMapProEditorDefaults.general.height;
                            $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
                            // this.$store.state.condogrid.editorSettings.self.redraw()
                        }
                    }
                    if (oldVal.id == newVal.id) {
                        if (oldVal.siteplan.shapes != newVal.siteplan.shapes) {
                            $.imageMapProEditorDefaults.spots = newVal.siteplan.shapes || [];
                        }
                    }
                },
                deep:true
                // if (oldVal.id == newVal.id) {
                //     console.log('changeimage', oldVal.siteplan.lots, newVal.siteplan.lots)
                //     // $.imageMapProEditorDefaults.image.url = newVal.siteplan.image
                //     //     $.imageMapProEditorDefaults.general.width = newVal.siteplan.width
                //     //     $.imageMapProEditorDefaults.general.height = newVal.siteplan.height
                //     //     $.imageMapProEditorDefaults.general.naturalWidth = $.imageMapProEditorDefaults.general.width;
                //     //     $.imageMapProEditorDefaults.general.naturalHeight = $.imageMapProEditorDefaults.general.height;
                //         $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
                //         // this.$store.state.condogrid.editorSettings.self.redraw()
                // }
                // },
                // deep:true
            },
            // currentFloor:{
            //     handler(val) {
            //         console.log('changeee')
            //     },
            //     deep:true
            // }


            // currentFloor(newVal,oldVal) {
            //     console.log('changeee',newVal)
            //     if (oldVal.id !== newVal.id){
            //         $.imageMapProEditorDefaults.spots = newVal.siteplan.shapes || [];
            //         if (!newVal.image) this.$store.commit('SITEPLAN_IMAGE_SETTINGS')
            //     }
            //     if (oldVal.image !== newVal.image){
            //         $.imageMapProEditorDefaults.image.url = newVal.siteplan.image
            //         $.imageMapProEditorDefaults.general.width = newVal.siteplan.width
            //         $.imageMapProEditorDefaults.general.height = newVal.siteplan.height

            //         $.imageMapProEditorDefaults.general.naturalWidth = $.imageMapProEditorDefaults.general.width;
            //         $.imageMapProEditorDefaults.general.naturalHeight = $.imageMapProEditorDefaults.general.height;
            //         $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
            //         this.$store.state.condogrid.editorSettings.self.redraw()
            //     }
            // }
            
            
        },
        computed: {
            changeimage() {
                return this.$store.condogrid.changeImage
            },
            currentFloor () {
                return this.$store.state.condogrid.currentFloor
            },
            siteplanSettings(){
                return this.$store.state.condogrid.siteplanSettings
            },
            instance() {
                return this.$store.state.instance
            },
            condogrid(){
                return this.$store.state.condogrid.appData
            },
            // siteplan(){
            //     return this.$store.getters.currentFloor
            // },
            currentElevations(){
                
            },
        },
        methods:{
            changeImage(){
                if (!this.baseImage) return this.$message.error('Please choose a Base Image')
                this.loadModal = true
                let width = document.querySelector('.baseImage-change').naturalWidth
                let height = document.querySelector('.baseImage-change').naturalHeight
                
                this.$api.put(`/siteplans/${this.instance.id}/${this.siteplan.id}`, {image:this.baseImage,width,height}).then( ({data}) => {
                    this.loadModal = false
                    this.$store.commit('SET_FLOOR_IMAGE',data)
                    this.$store.commit('CLOSE_SETTINGS')

                }).catch( err => {
                    let error = this.$err(err)
                    this.$message.error(error)
                })
            },
           setListItems(xxx){
               console.log('=>',xxx)
           }
        },
        created(){
            
        },
        mounted(){
            // window.hello = 'hejrklsjdflkjsdlkafjasdf'
            window.setListItems = this.setListItems;
            window.siteVue = this
            window.siteStore = this.$store
            $.imageMapProEditorDefaults.spots = this.currentFloor.siteplan.shapes || [];

            $.imageMapProEditorDefaults.image.url = this.currentFloor.siteplan.image
            $.imageMapProEditorDefaults.general.width = this.currentFloor.siteplan.width
            $.imageMapProEditorDefaults.general.height = this.currentFloor.siteplan.height

            $.imageMapProEditorDefaults.general.naturalWidth = $.imageMapProEditorDefaults.general.width;
            $.imageMapProEditorDefaults.general.naturalHeight = $.imageMapProEditorDefaults.general.height;
            $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
        },
        created() {
            console.log('sdafklsadjflkasdjl')


            // $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
            
        }

    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">

    [role="tablist"]{
        display:none;
    }
        
</style>